import { useEffect, useState } from "react";
import { fetcher } from "../../classes/Fetcher";
import { API_DOMAIN } from "../../constants";
import { Community } from "../../types/communityTypes";

export const useCommunitySearch = (query: string) => {
    const [results, setResults] = useState(null as null | Community[])
    useEffect(() => {
        const searchRequest = fetcher(`${API_DOMAIN}/communities/search/${query}`).then((response) => response.ok && response.json()).then((response: Community[] | false) => response && setResults(response));
    }, [query])
    return results;
}