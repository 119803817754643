import { ParallaxLayer } from '@react-spring/parallax'
import Link from 'next/link'

export const SecondLandingPage = () => (
  <ParallaxLayer speed={2} offset={0.867} factor={2.2}>
    <div className={`w-full h-full flex flex-row items-center justify-end`}>
      <div className={`w-1/2 h-min flex flex-row justify-start items-center`}>
        <div className={`flex flex-col gap-8 w-2/3 h-min items-start justify-start ml-12`}>
          <h2 className={`font-extrabold font-montserrat text-5xl`}>All Your Learning in One Place</h2>
          <p className={`font-wsans text-2xl`}>
            Disadus integrates with Schoology, to ensure everything is synced and easily accessible. Access your courses, assignments and more on Disadus!
          </p>
        </div>
      </div>
    </div>
  </ParallaxLayer>
)
