import { ParallaxLayer } from '@react-spring/parallax'
import Link from 'next/link'

export const FirstLandingPage = (props: { ready: number }) => (
  <ParallaxLayer speed={2}>
    <div className={`w-full h-[70%] flex flex-row items-center mt-[8%] ${props.ready < 2 && `hidden`}`}>
      <div className={`w-1/2 h-min relative flex flex-row justify-center pr-12`}>
        <div className={`w-min h-min relative flex flex-col gap-6 items-start`}>
          <h1 className={`${props.ready < 3 ? `scale-0` : `scale-100`} transition-all duration-700`}>
            <span
              className={`text-9xl xl:text-7xl font-extrabold dark:text-white font-montserrat bg-gradient-to-br from-emerald-400/80 to-cyan-500/80 text-transparent bg-clip-text transition-all`}
              style={{}}
            >
              Disadus
            </span>
          </h1>
          <h2
            className={`dark:text-gray-100/70 xl:text-sm text-2xl flex flex-row items-center gap-1.5 ${
              props.ready < 3 && `-translate-y-full opacity-0`
            } transition-all delay-500 duration-700 font-wsans`}
          >
            The modern tool students{' '}
            <span
              className={`font-bold dark:text-white bg-gradient-to-br from-emerald-400 to-cyan-400 text-transparent bg-clip-text ${
                props.ready < 3 && `-translate-y-full opacity-0`
              } transition-all delay-[900ms] duration-700`}
            >
              deserve
            </span>
          </h2>
          <div className={`flex flex-row gap-4 w-full items-center justify-start xl:text-sm`}>
            <Link href={`/signup`}>
              <button
                className={` bg-gradient-to-br from-emerald-400 to-cyan-500 dark:from-emerald-600 dark:to-cyan-700 dark:text-gray-50/80 dark:hover:from-emerald-700 dark:hover:to-cyan-800 transition-all text-white font-montserrat font-bold rounded-xl shadow-md hover:shadow-xl shadow-cyan-900/50 hover:shadow-cyan-900/80 py-2 px-4 xl:py-2 xl:px-4
                ${props.ready < 3 && `-translate-y-full opacity-0`} transition-all delay-[1200ms] duration-500
                `}
              >
                Get Started
              </button>
            </Link>
            <Link href={`/login`}>
              <span
                className={`cursor-pointer dark:text-gray-50/40 text-gray-700/40 hover:underline underline-offset-2 dark:hover:text-gray-50
                ${props.ready < 3 && `-translate-x-full opacity-0`} transition-all delay-[1700ms] duration-500
                `}
              >
                Login
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </ParallaxLayer>
)
