import { ParallaxLayer } from '@react-spring/parallax'
import Link from 'next/link'

export const ThirdLangingPage = () => (
  <ParallaxLayer speed={2} offset={1} factor={1}>
    <div className={`w-full h-full flex flex-row items-center`}>
      <div className={`w-1/2 h-min flex flex-row justify-end items-center`}>
        <div className={`flex flex-col gap-8 w-2/3 h-min items-start justify-start mr-20`}>
          <h2 className={`font-extrabold font-montserrat text-5xl xl:text-3xl`}>
            Designed for the 21st Century Student
          </h2>
          <p className={`font-wsans text-2xl xl:text-lg`}>
            Many other LMS systems on the market leave students with a barely functioning app. Disadus is designed with
            students in mind, easy to use, and intuitive alternative to other LMS
            systems.
          </p>
        </div>
      </div>
    </div>
  </ParallaxLayer>
)
